<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 7"
    title="Voeg batterij toe"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.batteryStepInvalid"
    :validation-state="wizardValidationStore.v$batteryRules"
    :disabled-next-button="disabledNextButton"
  >
    <template #content>
      <LoadingWrapper :loading="newSimulationStore.loadingKeyFigures">
        <InputSelect
          v-if="!demoStore.active"
          v-model="batteryPreset"
          data-cy="battery-preset"
          :options="batteryOptions"
          label="name"
          placeholder="Kies batterij"
          select-title="Kies een standaard batterij"
          track-by="name"
        />

        <InputField
          v-model="wizardStore.battery.capacity"
          type="number"
          data-cy="battery-capacity"
          label="Capaciteit"
          placeholder="Bijv. 10.000"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.battery.capacity,
              'Capaciteit',
            )
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.battery.capacity.$errors
              .length
          "
          @update:model-value="
            wizardValidationStore.v$batteryRules.battery.capacity.$touch()
          "
        >
          <template #addonRight> kWh </template>
        </InputField>
        <InputField
          v-model="wizardStore.battery.power"
          data-cy="battery-power"
          type="number"
          label="Vermogen"
          placeholder="Bijv. 2.000"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.battery.power,
              'Vermogen',
            )
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.battery.power.$errors.length
          "
          @update:model-value="
            wizardValidationStore.v$batteryRules.battery.power.$touch()
          "
        >
          <template #addonRight> kW </template>
        </InputField>

        <div class="flex flex-col gap-1">
          <InputSelect
            v-model="wizardStore.battery.technology"
            :options="batteryTechnologyOptions"
            data-cy="battery-technology"
            track-by="name"
            select-title="Kies technologie"
            label="name"
            placeholder="Kies technologie"
            :helper-text="
              vuelidateErrors(
                wizardValidationStore.v$batteryRules.battery.technology,
                'Technologie',
              )
            "
            :state-error="
              !!wizardValidationStore.v$batteryRules.battery.technology.$errors
                .length
            "
          />
        </div>

        <InputField
          v-model="wizardStore.battery.capex"
          type="string"
          data-cy="battery-capex"
          label="Investeringskosten batterij"
          placeholder="Bijv. 2.000"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.battery.capex,
              'Investeringskosten',
            )
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.battery.capex.$errors.length
          "
          @update:model-value="
            wizardValidationStore.v$batteryRules.battery.capex.$touch()
          "
        >
          <template #labelSuffix>
            <WikiTooltipComponent path="/bst/wizard/battery-investment" />
          </template>
          <template #addonLeft>
            <BaseIcon name="money-euro-circle" />
          </template>
        </InputField>
        <div class="gap-2">
          <div class="flex items-center">
            <CheckboxItem
              v-model="wizardStore.battery.capexIncludesInstallation"
              data-cy="battery-capex-includes-installation"
            >
              Inclusief de installatiekosten
              <WikiTooltipComponent path="/bst/wizard/installation-costs" />
            </CheckboxItem>
          </div>
          <small v-if="!wizardStore.battery.capexIncludesInstallation">
            Voor eenmalige kosten voor het aansturen van de batterij wordt 10%
            van de investeringskosten aangehouden.
          </small>
        </div>
        <RadioButtonList
          v-model="wizardStore.strategy"
          variant="default"
          data-cy="battery-strategy"
          title="Strategie"
          sub-title="Kies de strategie die het beste bij jouw situatie past"
          :items="strategyOptions"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.strategy,
              'Strategie',
            )
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.strategy.$errors.length
          "
        >
          <template
            v-for="(option, index) in strategyOptions"
            #[option.label]
            :key="index"
          >
            {{ option.label }}
            <WikiTooltipComponent class="ml-1" :path="option.tooltip" />
          </template>
        </RadioButtonList>
        <div class="flex items-center gap-1" @click="toggleExpansionSettings">
          <BaseButton
            color="subtle"
            small
            square
            data-cy="button-advanced-settings"
          >
            <BaseIcon
              :name="'arrow-right-s'"
              :class="iconRotation"
              class="duration-300"
            />
          </BaseButton>
          <p class="font-medium hover:cursor-pointer">
            Geavanceerde instellingen
          </p>
        </div>
        <transition name="expand-fade">
          <div
            v-show="isExpandedSettings"
            class="rounded-lg"
            :class="isExpandedSettings ? '' : 'overflow-hidden'"
          >
            <div class="flex flex-col gap-2">
              <InputField
                v-model="wizardStore.keyFigures.optimizationFeeFactor"
                data-cy="optimization-fee-factor"
                label="Kosten aansturing en optimalisatie"
                placeholder="0"
                type="number"
                :helper-text="
                  vuelidateErrors(
                    wizardValidationStore.v$keyFigureRules.keyFigures
                      .optimizationFeeFactor,
                    'Aansturing en optimalisatie',
                    'percentage',
                  )
                "
                :state-error="
                  !!wizardValidationStore.v$keyFigureRules.keyFigures
                    .optimizationFeeFactor.$errors.length
                "
                @update:model-value="
                  wizardValidationStore.v$keyFigureRules.keyFigures.optimizationFeeFactor.$touch()
                "
              >
                <template #labelSuffix>
                  <WikiTooltipComponent path="/bst/results/optimization-fee" />
                </template>
                <template #addonRight> % </template>
              </InputField>
              <InputField
                v-model="wizardStore.keyFigures.monthlyCosts"
                data-cy="monthly-costs"
                label="Vaste maandelijkse kosten"
                placeholder="0"
                type="string"
                :helper-text="
                  vuelidateErrors(
                    wizardValidationStore.v$keyFigureRules.keyFigures
                      .monthlyCosts,
                    'Vaste maandelijkse kosten',
                  )
                "
                :state-error="
                  !!wizardValidationStore.v$keyFigureRules.keyFigures
                    .monthlyCosts.$errors.length
                "
                @update:model-value="
                  wizardValidationStore.v$keyFigureRules.keyFigures.monthlyCosts.$touch()
                "
              >
                <template #labelSuffix>
                  <WikiTooltipComponent
                    path="/bst/wizard/fixed-monthly-costs"
                  />
                </template>
                <template #addonLeft>
                  <BaseIcon name="money-euro-circle" />
                </template>
              </InputField>
              <div class="flex items-center gap-1">
                <p class="text-sm font-medium">Investeringsjaar</p>
                <WikiTooltipComponent
                  class="text-sm"
                  path="/bst/wizard/investment-year"
                />
              </div>
              <DatePicker
                v-model="wizardStore.investmentYear"
                mode="year-picker"
                :min-date="minDate"
                :max-date="maxDate"
                format="yyyy"
              />
              <InputField
                v-model="wizardStore.discountRate"
                data-cy="discount-rate"
                label="Disconteringsvoet"
                placeholder="2"
                type="number"
                :helper-text="
                  vuelidateErrors(
                    wizardValidationStore.v$keyFigureRules.discountRate,
                    'Disconteringsvoet',
                    'percentage',
                  )
                "
                :state-error="
                  !!wizardValidationStore.v$keyFigureRules.discountRate.$errors
                    .length
                "
                @update:model-value="
                  wizardValidationStore.v$keyFigureRules.discountRate.$touch()
                "
              >
                <template #labelSuffix>
                  <WikiTooltipComponent path="/bst/wizard/discount-rate" />
                </template>
                <template #addonRight> % </template>
              </InputField>
              <div class="flex flex-col gap-1">
                <InputField
                  v-model="wizardStore.keyFigures.eia"
                  type="number"
                  data-cy="eia"
                  label="EIA toepassing"
                  placeholder="Bijv. 10%"
                  :helper-text="
                    vuelidateErrors(
                      wizardValidationStore.v$keyFigureRules.keyFigures.eia,
                      'EIA toepassing',
                      'percentage',
                    ) ||
                    'Energie-investeringsaftrek is afhankelijk van de winst van de organisatie'
                  "
                  :state-error="
                    !!wizardValidationStore.v$keyFigureRules.keyFigures.eia
                      .$errors.length
                  "
                  @update:model-value="
                    wizardValidationStore.v$keyFigureRules.keyFigures.eia.$touch()
                  "
                >
                  <template #labelSuffix>
                    <WikiTooltipComponent path="/bst/wizard/eia" />
                  </template>
                  <template #addonRight> % </template>
                </InputField>
              </div>
              <p class="text-sm font-medium">Onbalansfactoren:</p>
              <div class="flex gap-2">
                <InputField
                  v-model="wizardStore.keyFigures.takeOff"
                  data-cy="factor-take-off"
                  label="Levering"
                  placeholder="0,15"
                  type="string"
                  :helper-text="
                    vuelidateErrors(
                      wizardValidationStore.v$keyFigureRules.keyFigures.takeOff,
                      'Onbalansfactor levering',
                    )
                  "
                  :state-error="
                    !!wizardValidationStore.v$keyFigureRules.keyFigures.takeOff
                      .$errors.length
                  "
                  @update:model-value="
                    wizardValidationStore.v$keyFigureRules.keyFigures.takeOff.$touch()
                  "
                >
                  <template #labelSuffix>
                    <WikiTooltipComponent path="/bst/wizard/takeoff-factor" />
                  </template>
                  <template #addonRight> €/MWh </template>
                </InputField>
                <InputField
                  v-model="wizardStore.keyFigures.feedIn"
                  data-cy="factor-feed-in"
                  label="Teruglevering"
                  placeholder="0,14"
                  type="string"
                  :helper-text="
                    vuelidateErrors(
                      wizardValidationStore.v$keyFigureRules.keyFigures.feedIn,
                      'Onbalansfactor teruglevering',
                    )
                  "
                  :state-error="
                    !!wizardValidationStore.v$keyFigureRules.keyFigures.feedIn
                      .$errors.length
                  "
                  @update:model-value="
                    wizardValidationStore.v$keyFigureRules.keyFigures.feedIn.$touch()
                  "
                >
                  <template #labelSuffix>
                    <WikiTooltipComponent path="/bst/wizard/feedin-factor" />
                  </template>
                  <template #addonRight> €/MWh </template>
                </InputField>
              </div>
              <InputField
                v-model="wizardStore.keyFigures.batteryCycles"
                label="Cycli totale levensduur"
                data-cy="battery-cycles"
                placeholder="7000"
                type="number"
                :helper-text="
                  vuelidateErrors(
                    wizardValidationStore.v$keyFigureRules.keyFigures
                      .batteryCycles,
                    'Aantal cycli',
                  )
                "
                :state-error="
                  !!wizardValidationStore.v$keyFigureRules.keyFigures
                    .batteryCycles.$errors.length
                "
                @update:model-value="
                  wizardValidationStore.v$keyFigureRules.keyFigures.batteryCycles.$touch()
                "
              />
              <p class="text-sm font-medium">Capaciteit:</p>
              <div class="flex gap-2">
                <InputField
                  v-model="importCapacity"
                  label="Teruglevering nieuwe situatie"
                  data-cy="import-capacity"
                  placeholder="0"
                  type="number"
                  :helper-text="
                    vuelidateErrors(
                      wizardValidationStore.v$newGridRules.newGrid
                        .importCapacity,
                      'Teruglevercapaciteit',
                    )
                  "
                  :state-error="
                    !!wizardValidationStore.v$newGridRules.newGrid
                      .importCapacity.$errors.length
                  "
                  @update:model-value="
                    wizardValidationStore.v$newGridRules.newGrid.importCapacity.$touch()
                  "
                >
                  <template #labelSuffix>
                    <WikiTooltipComponent path="/bst/wizard/takeoff-capacity" />
                  </template>
                  <template #addonRight> kW </template>
                </InputField>
                <InputField
                  v-model="exportCapacity"
                  label="Levering nieuwe situatie"
                  data-cy="export-capacity"
                  placeholder="0"
                  type="number"
                  :helper-text="
                    vuelidateErrors(
                      wizardValidationStore.v$newGridRules.newGrid
                        .exportCapacity,
                      'Afnamecapaciteit',
                    )
                  "
                  :state-error="
                    !!wizardValidationStore.v$newGridRules.newGrid
                      .exportCapacity.$errors.length
                  "
                  @update:model-value="
                    wizardValidationStore.v$newGridRules.newGrid.exportCapacity.$touch()
                  "
                >
                  <template #labelSuffix>
                    <WikiTooltipComponent path="/bst/wizard/feedin-capacity" />
                  </template>
                  <template #addonRight> kW </template>
                </InputField>
              </div>
            </div>
          </div>
        </transition>
      </LoadingWrapper>
    </template>
    <template #nextButton>
      <LoadingWrapper :loading="newSimulationStore.loadingSimulation" compact>
        <div class="flex items-center gap-2">
          <span>Bekijk resultaten</span>
          <BaseIcon name="arrow-right" :filled="false" />
        </div>
      </LoadingWrapper>
    </template>
    <template #summary>
      {{ wizardStore.battery.technology.name }}
      &bull;&nbsp;{{ formatNumber(wizardStore.battery.capacity, 0) }}&nbsp;kWh
      &bull;&nbsp;{{ formatNumber(wizardStore.battery.power, 0) }}&nbsp;kW
    </template>
  </WizardStep>
</template>

<script setup>
import { computed, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { formatNumber } from "@/services/formatterService.js"
import { watchOnce } from "@vueuse/core"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import {
  batteryTechnologyOptions,
  strategyOptions,
  useWizardStore,
} from "@/stores/wizardStore"
import { useDemoStore } from "@/stores/useDemoStore"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import useNewSimulationStore from "@/stores/newSimulationStore"
import { useNotificationStore } from "@/stores/useNotificationStore"
import {
  BaseIcon,
  InputField,
  InputSelect,
  LoadingWrapper,
  RadioButtonList,
  CheckboxItem,
  BaseButton,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"
import DatePicker from "@/components/input/DatePicker.vue"

const props = defineProps({
  /**
   * Used to determine which step is currently active
   */
  isActive: {
    type: Boolean,
    required: true,
  },
  /**
   * For determining wether to show the finished checkmark and summary of the
   * inputfields
   */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to determine if the step is enabled
   */
  isEnabled: {
    type: Boolean,
    default: true,
  },
  disabledNextButton: {
    type: Boolean,
    default: false,
  },
})

const demoStore = useDemoStore()
const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const notificationStore = useNotificationStore()
const batteryPreset = ref(null)
const wizardStepRef = ref()
const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)
const isExpandedSettings = ref(false)
const minDate = new Date()
const MAXIMUM = {
  YEAR: 2030,
  MONTH: 0,
  DAY: 1,
}
const maxDate = new Date(MAXIMUM.YEAR, MAXIMUM.MONTH, MAXIMUM.DAY)
const iconRotation = computed(() => {
  return {
    "rotate-0": !isExpandedSettings.value,
    "rotate-90": isExpandedSettings.value,
  }
})
const route = useRoute()
const batteryOptions = ref([])

const exportCapacity = computed({
  get: () => wizardStore.newGrid.exportCapacity,
  set: (value) => {
    wizardStore.newGrid.exportCapacity =
      typeof value === "string" ? null : value
  },
})

const importCapacity = computed({
  get: () => wizardStore.newGrid.importCapacity,
  set: (value) => {
    wizardStore.newGrid.importCapacity =
      typeof value === "string" ? null : value
  },
})

watch(batteryPreset, (option) => {
  if (option && !demoStore.active) {
    wizardStore.battery.capacity = option.capacity
    wizardStore.battery.power = option.power
    wizardStore.battery.capex = option.price
  }
})

/**
 * Sets the battery type to Lithium the first time a preset is selected
 */
watchOnce(batteryPreset, () => {
  wizardStore.battery.technology = batteryTechnologyOptions[0]
})

/**
 * Loads the battery options from the API the first time this step is activated
 */

watchOnce(
  () => props.isActive,
  async (isActive) => {
    if (!isActive) {
      return
    }
    const uid = route.params.uid || newSimulationStore.uid
    newSimulationStore.getKeyFigures(
      uid,
      (keyfigures) => {
        wizardStore.loadKeyFigures(keyfigures)
        batteryOptions.value = keyfigures ? keyfigures.battery_presets : []
      },
      (error) => {
        notificationStore.pushError(
          "Geavanceerde instellingen niet beschikbaar",
          `De geavanceerde instellingen konden niet worden opgehaald (code: ${error.code}).
          Standaard instellingen worden gebruikt.`,
          "loading-key-figures-failed",
        )
      },
    )
  },
)

function toggleExpansionSettings() {
  isExpandedSettings.value = !isExpandedSettings.value
}

defineExpose({ getValidationState })
</script>

<style scoped>
.expand-fade-enter-active {
  transition:
    max-height 0.4s ease,
    opacity 0.4s ease;
}
.expand-fade-leave-active {
  transition:
    max-height 0.2s ease-out,
    opacity 0.2s ease-out;
}
.expand-fade-enter-from,
.expand-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-fade-enter-to,
.expand-fade-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>

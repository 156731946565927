import { defineStore } from "pinia"
import { computed, watch } from "vue"
import {
  minLength,
  required,
  minValue,
  decimal,
  requiredIf,
  between,
  sameAs,
  integer,
} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useWizardStore } from "./wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore"

const MINIMAL_NAME_LENGTH = 3
const INDEX = {
  CREATE_SIM: 1,
  CONNECTION: 2,
  DATA: 3,
  CONSUMPTION: 4,
  GENERATE: 5,
  CALCULATION: 6,
  BATTERY: 7,
}

export const useWizardValidationStore = defineStore(
  "wizardValidationStore",
  () => {
    const wizardStore = useWizardStore()
    const newSimulationStore = useNewSimulationStore()
    /**
     * Simulation step validation rules
     */
    const simulationStepInvalid = computed(() => {
      return v$simulationRules.value.$invalid
    })
    const editSimulationRules = computed(() => ({
      newName: {
        required,
        minLength: minLength(MINIMAL_NAME_LENGTH),
      },
    }))
    const v$editSimulationRules = useVuelidate(
      editSimulationRules.value,
      wizardStore,
    )

    const simulationRules = computed(() => ({
      simulation: {
        name: {
          required,
          minLength: minLength(MINIMAL_NAME_LENGTH),
        },
        validName: {
          sameAs: sameAs(true),
        },
        simDescription: {
          required,
        },
        endDate: {
          required,
        },
      },
    }))
    const v$simulationRules = useVuelidate(simulationRules.value, wizardStore)

    /**
     * Connection step validation rules
     */
    const connectionStepInvalid = computed(() => {
      return v$connectionRules.value.$invalid
    })
    const connectionRules = computed(() => ({
      grid: {
        importCapacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        exportCapacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        operator: {
          required,
        },
      },
    }))
    const v$connectionRules = useVuelidate(connectionRules.value, wizardStore)

    /**
     * Data step validation rules
     */
    const dataStepInvalid = computed(() => {
      return v$dataRules.value.$invalid
    })
    const dataRules = computed(() => ({
      excel: {
        demandTable: {
          required: requiredIf(() => wizardStore.useExcelData),
        },
        columns: {
          required: requiredIf(() => wizardStore.useExcelData),
        },
      },
    }))
    const v$dataRules = useVuelidate(dataRules.value, wizardStore)

    /**
     * Consumption/Demand step validation rules
     */
    const consumptionStepInvalid = computed(() => {
      return v$consumptionRules.value.$invalid
    })
    const consumptionRules = computed(() => ({
      demand: {
        profile: {
          required,
        },
        yearly: {
          required,
          decimal,
          minValue: minValue(0),
        },
      },
    }))
    const v$consumptionRules = useVuelidate(consumptionRules.value, wizardStore)

    /**
     * Generate/Demand step validation rules
     */
    const generateStepInvalid = computed(() => {
      return v$generateRules.value.$invalid
    })

    const generateRules = computed(() => {
      return {
        solarParks: {
          $each: wizardStore.solarParks.map(() => ({
            orientation: {
              required,
            },
            AC: {
              required,
              decimal,
              minValue: minValue(0),
            },
            DC: {
              required,
              decimal,
              minValue: minValue(0),
            },
          })),
          // Ensure orientation is not falsey
          required: () =>
            wizardStore.solarParks.some((park) => park.orientation),
        },
      }
    })
    const v$generateRules = useVuelidate(generateRules, wizardStore)

    /**
     * Calculation step validation rules
     */
    const calculationStepInvalid = computed(() => {
      return v$calculationRules.value.$invalid
    })

    const calculationRules = computed(() => ({
      uid: {
        required,
      },
      dataframes: {
        required,
      },
    }))
    const v$calculationRules = useVuelidate(
      calculationRules,
      newSimulationStore,
    )

    /**
     * Battery step validation rules
     */
    const batteryStepInvalid = computed(() => {
      return v$batteryRules.value.$invalid
    })

    const batteryRules = computed(() => ({
      battery: {
        capacity: {
          required,
          decimal,
          minValue: minValue(0),
        },
        power: {
          required,
          decimal,
          minValue: minValue(0),
        },
        technology: {
          required,
        },
        capex: {
          required,
          decimal,
          minValue: minValue(0),
        },
      },
      strategy: {
        required,
      },
    }))
    const v$batteryRules = useVuelidate(batteryRules, wizardStore)

    const newGridRules = computed(() => ({
      newGrid: {
        importCapacity: {
          integer,
        },
        exportCapacity: {
          integer,
        },
      },
    }))
    const v$newGridRules = useVuelidate(newGridRules, wizardStore)

    const keyFigureRules = computed(() => ({
      discountRate: {
        decimal,
        between: between(0, 100),
      },
      keyFigures: {
        eia: {
          decimal,
          between: between(0, 100),
        },
        optimizationFeeFactor: {
          decimal,
          between: between(0, 100),
        },
        batteryCycles: {
          integer,
        },
        monthlyCosts: {
          decimal,
        },

        feedIn: {
          decimal,
          between: between(0, 1),
        },
        takeOff: {
          decimal,
          between: between(0, 1),
        },
      },
    }))

    const v$keyFigureRules = useVuelidate(keyFigureRules, wizardStore)

    const validationRules = {
      CREATE_SIM: v$simulationRules,
      CONNECTION: v$connectionRules,
      DATA: v$dataRules,
      CONSUMPTION: v$consumptionRules,
      GENERATE: v$generateRules,
      CALCULATION: v$calculationRules,
      BATTERY: v$batteryRules,
    }

    /**
     * All steps validation rules
     */
    const disabledSteps = computed(() => {
      const disabled = {}

      disabled[INDEX.CONSUMPTION] =
        !wizardStore.simulation.simDescription?.includes("verbruik") ||
        (wizardStore.useExcelData && wizardStore.excel.columns !== "BPM")
      disabled[INDEX.GENERATE] =
        !wizardStore.simulation.simDescription?.includes("opwek") ||
        (wizardStore.useExcelData &&
          wizardStore.excel.columns !== "LDN" &&
          wizardStore.excel.columns !== "ODN, LDN")

      const baseStepsValid =
        !simulationStepInvalid.value &&
        !connectionStepInvalid.value &&
        !dataStepInvalid.value

      const consumptionValid =
        disabled[INDEX.CONSUMPTION] || !consumptionStepInvalid.value

      const generateValid =
        disabled[INDEX.GENERATE] || !generateStepInvalid.value

      disabled[INDEX.CALCULATION] = !(
        baseStepsValid &&
        consumptionValid &&
        generateValid
      )

      return disabled
    })

    /**
     * All steps validation computed property
     */
    const allStepsValid = computed(() => {
      const stepOne = !simulationStepInvalid.value
      const stepTwo = !connectionStepInvalid.value
      const stepThree = !dataStepInvalid.value && v$dataRules.value.$dirty
      const stepFour = !consumptionStepInvalid.value
      const stepFive = !generateStepInvalid.value
      const stepSix = !calculationStepInvalid.value
      const stepSeven = !batteryStepInvalid.value

      const stepFourDisabled = disabledSteps.value[4]
      const stepFiveDisabled = disabledSteps.value[5]

      return (
        stepOne &&
        stepTwo &&
        stepThree &&
        (stepFourDisabled || stepFour) &&
        (stepFiveDisabled || stepFive) &&
        stepSix &&
        stepSeven
      )
    })

    watch(
      () => wizardStore.currentStep,
      (newValue, oldValue) => {
        const newStep = Object.entries(INDEX).find(
          ([_, value]) => value === newValue,
        )?.[0]
        const oldStep = Object.entries(INDEX).find(
          ([_, value]) => value === oldValue,
        )?.[0]

        if (newStep === "BATTERY" && !newSimulationStore.dataframes) {
          validationRules[newStep]?.value.$reset()
        } else if (oldStep) {
          validationRules[oldStep]?.value.$validate()
        } else {
          return
        }
      },
    )

    return {
      simulationRules,
      simulationStepInvalid,
      v$simulationRules,
      connectionRules,
      connectionStepInvalid,
      v$connectionRules,
      dataRules,
      dataStepInvalid,
      v$dataRules,
      consumptionRules,
      consumptionStepInvalid,
      v$consumptionRules,
      generateStepInvalid,
      generateRules,
      v$generateRules,
      calculationStepInvalid,
      calculationRules,
      v$calculationRules,
      batteryStepInvalid,
      v$batteryRules,
      batteryRules,
      allStepsValid,
      disabledSteps,
      validationRules,
      editSimulationRules,
      v$editSimulationRules,
      v$keyFigureRules,
      v$newGridRules,
      INDEX,
    }
  },
)

import { defineStore } from "pinia"
import { postApiJson, fetchApiCatch } from "@/services/bstApiService"
import { excelToArray } from "@/services/excelService.js"
import { useDebounceFn } from "@vueuse/core"
import { format } from "date-fns"

const MINIMUM_NAME_LENGTH = 3
const DEBOUNCE_DELAY = 300
const DEFAULT_DISCOUNT_RATE = 2
const DEFAULT_BATTERY_CYCLES = 7000
const DEFAULT_OPTIMIZATION_FEE_FACTOR = 0
const DEFAULT_EIA_FACTOR = 0
const DEFAULT_FIXED_MONTHLY_COSTS = 0
const DEFAULT_IMBALANCE_FEED_IN_FACTOR = 0.11
const DEFAULT_IMBALANCE_TAKE_OFF_FACTOR = 0.15

export const operatorOptions = [
  { name: "Liander", value: "Liander" },
  { name: "Enexis", value: "Enexis" },
  { name: "Stedin", value: "Stedin" },
  { name: "Westland Infra", value: "Westland Infra" },
  { name: "Rendo", value: "Rendo" },
  { name: "Coteq", value: "Coteq" },
  { name: "Tennet", value: "Tennet" },
]

export const excelColumnOptions = [
  { label: "LDN", value: "LDN", tooltip: "Levering Door Netbeheerder" },
  { label: "BPM", value: "BPM", tooltip: "Bruto Productie Meter" },
  {
    label: "ODN en LDN",
    value: "ODN, LDN",
    tooltip: "Ontvangen Door Netbeheerder en Levering Door Netbeheerder",
  },
  {
    label: "ODN, LDN en BPM",
    value: "ODN, LDN, BPM",
    tooltip:
      "Ontvangen Door Netbeheerder, Levering Door Netbeheerder en Bruto Productie Meter",
  },
]

export const orientationOptions = [
  { name: "Oost-west", value: "east_west" },
  { name: "Zuid", value: "south" },
]

export const batteryTechnologyOptions = [
  { name: "Lithium", value: "li" },
  { name: "Vanadium", value: "va" },
]

export const strategyOptions = [
  {
    tooltip: "/bst/wizard/financial-strategy",
    label: "Financiële optimalisatie onbalansmarkt",
    value: "imbalance_optimization",
  },
  {
    tooltip: "/bst/wizard/self-sufficiency",
    label: "Energetische optimalisatie zelflevering",
    value: "self_sufficiency",
  },
  {
    tooltip: "/bst/wizard/epex-optimization",
    label: "Financiële optimalisatie day-ahead markt",
    value: "epex_optimization",
  },
]

export const useWizardStore = defineStore("wizardStore", {
  /**
   * Variables marked as 'InputSelect item' are objects with structure
   * { name: string, value: string }, which should be accounted for when passing
   * data to the backend.
   */
  state: () => ({
    currentStep: 1,
    newName: null,
    simulation: {
      originalName: null,
      name: null,
      validName: null,
      simDescription: "",
      endDate: {
        // Periodically we will manually update the endDate
        month: 8,
        year: 2024,
      },
    },
    grid: {
      importCapacity: null,
      exportCapacity: null,
      operator: null, // InputSelect item
    },
    newGrid: {
      importCapacity: null,
      exportCapacity: null,
      operator: null,
    },
    excel: {
      demandTable: null,
      columns: null,
      errorList: null,
    },
    demand: {
      profile: null, // InputSelect item
      yearly: null,
    },
    solarParks: [], // 'orientation' within is an InputSelect item
    battery: {
      capacity: null,
      power: null,
      technology: null, // InputSelect item
      capex: null,
      capexIncludesInstallation: true,
    },
    investmentYear: 2024,
    discountRate: DEFAULT_DISCOUNT_RATE,
    keyFigures: {
      optimizationFeeFactor: null,
      eia: null,
      batteryCycles: null,
      monthlyCosts: null,
      feedIn: null,
      takeOff: null,
    },
    strategy: null,
    useExcelData: false,
  }),
  getters: {
    /**
     * Data to be sent to the backend for intermediate results.
     */
    intermediateInput: (state) => {
      const obj = {
        name: state.simulation.name,
        simulation_description: state.simulation.simDescription,
        end_date: format(
          new Date(
            state.simulation.endDate.year,
            state.simulation.endDate.month,
          ),
          "yyyy-MM-dd",
        ),
        grid: {
          import_capacity: state.grid.importCapacity,
          export_capacity: state.grid.exportCapacity,
          operator: state.grid.operator.value, // InputSelect item
        },
      }
      if (state.useExcelData) {
        obj.demand_table = state.excel.demandTable
        obj.BPM = state.excel.columns.includes("BPM")
        obj.ODN = state.excel.columns.includes("ODN")
        obj.LDN = state.excel.columns.includes("LDN")
      }
      if (state.demand.profile) {
        obj.demand_profile = state.demand.profile.value
        obj.demand_yearly = state.demand.yearly
      }
      if (state.solarParks.length) {
        obj.solar_parks = state.solarParks.map((park) => ({
          orientation: park.orientation.value,
          AC: park.AC,
          DC: park.DC,
        }))
      }
      return obj
    },
    /**
     * Data to be sent to the backend for a new simulation. The 'demand_table'
     * key is not included since it is already stored in the backend during the
     * intermediate results step.
     */
    simulationInput: (state) => {
      const obj = {
        ...state.intermediateInput,
        battery: {
          capacity: state.battery.capacity,
          power: state.battery.power,
          technology: state.battery.technology?.value,
          costs: {
            capex: state.battery.capex || 0,
            capex_includes_installation:
              state.battery.capexIncludesInstallation,
          },
        },
        cycles: state.batteryCycles || DEFAULT_BATTERY_CYCLES,
        new_grid: {
          export_capacity:
            state.newGrid.exportCapacity ?? state.grid.exportCapacity,
          import_capacity:
            state.newGrid.importCapacity ?? state.grid.importCapacity,
          operator: state.grid.operator.value,
        },
        investment_year: state.investmentYear,
        discount_rate: state.discountRate / 100 || DEFAULT_DISCOUNT_RATE,
        custom_key_figures: {
          optimization_fee_factor: state.keyFigures.optimizationFeeFactor / 100,
          EIA_factor: state.keyFigures.eia / 100,
          battery_total_cycles: state.keyFigures.batteryCycles,
          fixed_monthly_costs: state.keyFigures.monthlyCosts,
          imbalance_feed_in_factor: state.keyFigures.feedIn,
          imbalance_take_off_factor: state.keyFigures.takeOff,
        },
        strategy: state.strategy,
      }
      delete obj.demand_table
      return obj
    },
  },
  actions: {
    /**
     * Manage entries in the 'solarParks' state variable
     */
    addSolarPark() {
      this.solarParks.push({
        orientation: null,
        AC: "",
        DC: "",
      })
    },
    removeSolarPark(index) {
      this.solarParks.splice(index, 1)
    },
    /**
     * Validate the Excel file in the backend store the validated result.
     */
    async validateExcelFile(file, onValid, onInvalid, onError) {
      this.excel.demandTable = null
      excelToArray(file)
        .then((excelArray) => {
          const body = {
            excel_data: excelArray,
            BPM: this.excel.columns.includes("BPM"),
            ODN: this.excel.columns.includes("ODN"),
            LDN: this.excel.columns.includes("LDN"),
          }
          return postApiJson("/v2/validate-uploaded-data/", body)
        })
        .then((data) => {
          this.excel.errorList = data.error_list
          if (data.valid) {
            this.excel.demandTable = data.demand_table
            onValid?.(data)
          } else {
            onInvalid?.(data)
          }
        })
        .catch((error) => {
          onError?.(error)
        })
    },
    validateName: useDebounceFn(function (name) {
      if (name?.length >= MINIMUM_NAME_LENGTH) {
        if (name === this.simulation.originalName) {
          this.simulation.validName = true
        } else {
          fetchApiCatch(`/v2/simulations/name-available/?name=${name}`)
            .then(() => (this.simulation.validName = true))
            .catch(() => (this.simulation.validName = false))
        }
      }
    }, DEBOUNCE_DELAY),
    /**
     * Load a simulation configuration into the store's state.
     */
    loadConfiguration(config) {
      // Wizard state variables that are always present
      const date = new Date(config.end_date)
      const newState = {
        simulation: {
          originalName: config.name,
          name: config.name,
          simDescription: config.simulation_description,
          endDate: {
            month: date.getMonth(),
            year: format(date, "yyyy"),
          },
        },
        grid: {
          importCapacity: config.grid.import_capacity,
          exportCapacity: config.grid.export_capacity,
          operator: operatorOptions.find(
            (option) => option.value === config.grid.operator,
          ),
        },
        newGrid: {
          importCapacity: config.new_grid.import_capacity,
          exportCapacity: config.new_grid.export_capacity,
          operator: operatorOptions.find(
            (option) => option.value === config.new_grid.operator,
          ),
        },
        battery: {
          capacity: config.battery.capacity,
          power: config.battery.power,
          technology: batteryTechnologyOptions.find(
            (option) => option.value === config.battery.technology,
          ),
          capex: config.battery.costs.capex,
          capexIncludesInstallation:
            config.battery.costs.capex_includes_installation,
        },
        investmentYear: config.investment_year,
        discountRate: config.discount_rate * 100,
        strategy: config.strategy,
        useExcelData: config.BPM || config.ODN || config.LDN,
      }
      // Optional wizard state variables
      if (newState.useExcelData) {
        const flags = { ODN: config.ODN, LDN: config.LDN, BPM: config.BPM }
        newState.excel = {
          columns: Object.keys(flags)
            .filter((key) => flags[key])
            .join(", "),
          demandTable: config.demand_table,
          errorList: null,
        }
      }
      if (config.demand_profile) {
        newState.demand = {
          profile: {
            name: config.demand_profile,
            value: config.demand_profile,
          },
          yearly: config.demand_yearly,
        }
      }
      if (config.solar_parks) {
        newState.solarParks = config.solar_parks.map((park) => ({
          orientation: orientationOptions.find(
            (option) => option.value === park.orientation,
          ),
          AC: park.AC,
          DC: park.DC,
        }))
      }
      this.$patch(newState)
      this.validateName(newState.simulation.name)
    },
    loadKeyFigures(data) {
      const newState = {
        keyFigures: {
          optimizationFeeFactor:
            data?.key_figures?.optimization_fee_factor * 100 ||
            DEFAULT_OPTIMIZATION_FEE_FACTOR,
          eia: data?.key_figures?.EIA_factor * 100 || DEFAULT_EIA_FACTOR,
          batteryCycles:
            data?.key_figures?.battery_total_cycles || DEFAULT_BATTERY_CYCLES,
          monthlyCosts:
            data?.key_figures?.fixed_monthly_costs ||
            DEFAULT_FIXED_MONTHLY_COSTS,
          feedIn:
            data?.key_figures?.imbalance_feed_in_factor ||
            DEFAULT_IMBALANCE_FEED_IN_FACTOR,
          takeOff:
            data?.key_figures?.imbalance_take_off_factor ||
            DEFAULT_IMBALANCE_TAKE_OFF_FACTOR,
        },
      }
      this.$patch(newState)
    },
  },
})

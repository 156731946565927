export const vuelidateErrors = (field, label = "", context = "") => {
  if (!field?.$errors || !field?.$dirty) {
    return null
  }

  const errors = field.$errors.map((item) => {
    const { $validator, $params, $message } = item

    const messages = {
      decimal: `${label} moet een decimaal getal zijn`,
      email: "Geef een geldig e-mailadres op",
      required: `${label} is verplicht`,
      requiredIf: `${label} is verplicht`,
      integer: "Gebruik enkel gehele getallen",
      maxValue: `De maximale waarde is ${$params.max}`,
      minValue: `De minimaal toegestane waarde is ${$params.min}`,
      maxLength: `Het maximale aantal karakters is ${$params.max}`,
      minLength: `Het minimale aantal karakters is ${$params.min}`,
      password:
        "Een wachtwoord moet uit ten minste 8 tekens bestaan en één hoofdletter, kleine letter en cijfer bevatten",
      sameAs: "Velden komen niet overeen",
      between: `${label} moet tussen ${$params.min} en ${$params.max}${context === "percentage" ? "%" : ""} liggen`,
    }

    return messages[$validator] || $message
  })

  return errors.length > 0 ? errors[0] : null
}

import resolveConfig from "tailwindcss/resolveConfig"
import config from "tailwind-config"

const { theme } = resolveConfig(config)

export const colors = theme.colors

const fontFamilyArray = theme.fontFamily.sans
export const fontFamily = "'" + fontFamilyArray.join("', '") + "'"
export const organisationThemes = [
  "energyshift",
  "novar",
  "repowered",
  "hgenergie",
]
/**
 * Recursively traverse a color object from a Tailwind CSS theme and resolves
 * all CSS variables.
 */
function resolveColors(obj, style) {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      resolveColors(obj[key], style)
    } else {
      const cssVar = obj[key].match(/var\((--.*?)\)/)
      if (cssVar) {
        obj[key] = style.getPropertyValue(cssVar[1])
      }
    }
  }
}

export function getResolvedColors() {
  const { theme } = resolveConfig(config)
  const rawColors = theme.colors
  const style = getComputedStyle(document.getElementsByTagName("main")[0])
  resolveColors(rawColors, style)
  return rawColors
}
